<template>
  <table class="table is-fullwidth">
    <thead>
      <tr>
        <template v-for="(column, index) in columns">
          <th :class="[column.class]" v-if="column.colspan > 0" :key="column.id || index" :colspan="column.colspan">{{ column.header}}</th>
          <template v-else-if="column.colspan === 0"></template>
          <th
            :class="[column.class]"
            v-if="!column.colspan"
            :key="column.id || index"
            :colspan="column.colspan"
          >
            <div class="title_th">
              {{ column.header}}
              <div class="table_sort_container" v-if="column.orderBy">
                <span
                  class="material-icons icon_sort"
                  :class="{ 'active' : column.orderBy === 'asc' }"
                  @click="$emit('order-by-action', column.id, 'asc')"
                >
                  arrow_drop_up
                </span>
                <span
                  class="material-icons icon_sort"
                  :class="{ 'active' : column.orderBy === 'desc' }"
                  @click="$emit('order-by-action', column.id, 'desc')"
                >
                  arrow_drop_down
                </span>
              </div>
            </div>
          </th>
        </template>
      </tr>
    </thead>
    <tbody>
      <tr v-if="data.length === emptyData && !loading">
        <td
          colspan="40"
          class="no_information_td"
          :class="{ loading, empty: data.length === emptyData }"
          align="center"
        >
          <div class="block has-text-centered">
            <img class="image_main" src="@/assets/image/sin-informacion.png" alt="Sin Información">
          </div>
          <div class="block">
            <p class="font_15 has-text-weight-semibold has_gray has-text-centered">
              Aún no hay información para mostrar
            </p>
          </div>
      </td>
      </tr>
      <tr v-else-if="loading">
        <td
          colspan="40"
          :class="{ loading, empty: data.length === emptyData }"
          align="center"
        >
          <loading-component />
        </td>
      </tr>
      <template v-else v-for="(row, rowIndex) in data">
        <tr :key="composeTrKey(row, rowIndex)" @click="activate(rowIndex)" :class="{ 'is-selected' : activeTr == rowIndex }">
          <template v-for="(field, fieldIndex) in columns">
            <td :class="[field.class]" :key="composeTdKey(row, rowIndex, field, fieldIndex)" v-if="field.link">
              <router-link :to="createLink(field, row)">{{ printFieldData(field, row) }}</router-link>
            </td>
            <td :class="[field.class]" :key="composeTdKey(row, rowIndex, field, fieldIndex)" v-else>
              {{ printFieldData(field, row) }}
              <slot v-if="field.name" :name="field.name" :row="row"></slot>
            </td>
          </template>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import get from 'lodash/get'

export default {
  name: 'BaseTable',

  props: {
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: Boolean
    },
    pagination: {
      type: Object
    }
  },

  components: {
    LoadingComponent: () => import('@/components/UI/Loading')
  },

  data () {
    return {
      emptyData: 0,
      activeTr: null
    }
  },

  methods: {
    composeTrKey (row, rowIndex) {
      return `${row.id}.${rowIndex}`
    },
    composeTdKey (row, rowIndex, field, fieldIndex) {
      if (row.id && field.id) return `${row.id}.${field.id}`
      if (row.id && !field.id) return `${row.id}.${fieldIndex}`
      if (!row.id && field.id) return `${rowIndex}.${field.id}`
      return `${rowIndex}.${fieldIndex}`
    },
    printFieldData (field, row) {
      if (field.name) return ''
      if (typeof field.accessor === 'function') return field.accessor(row)
      if (typeof field.accessor === 'string') return get(row, field.accessor, '')
      if (field.id) return get(row, field.id, '')
      return ''
    },
    createLink (field, row) {
      if (field.type === 'ancla') return `#${row.route}`
      if (field.type === 'link') {
        if (typeof field.route === 'function') return field.route(row)
        if (typeof field.route === 'string') return get(row, field.route, '')
        return ''
      }
    },
    activate (el) {
      this.activeTr = el
    }
  }
}
</script>
<style lang="scss" scoped>
  .no_information_td {
    height: 50vh;
    vertical-align: middle;
    background-color: #ffffff;
  }

  table {
    border-collapse: collapse;
    box-shadow: 0 3px 15px 0 rgba(188, 188, 222, 0.27);
    overflow: hidden;
    margin: 1rem 0 0 0;
  }
  .table th {
    border: none;
  }
  .table td {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.14px;
    text-align: left;
    color: #5d5d5d;
    padding: 0.75em 1em;
    background-color: #ffffff;
  }
  tr th {
    background-color: #e7e9f0;
    color: #5d5d5d;
    white-space: nowrap;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.15px;
    padding: 20px 15px;
  }
  // Bordes tabla
  tr td {
    border-bottom: solid 1px #e6e6e6;
  }
  // Hover row
  tr:hover td {
    font-weight: 600;
  }
  .table tr.is-selected,
  .table tr.is-selected td {
    background-color: inherit;
    color: #5d5d5d;
    font-weight: 600;
    border-bottom: solid 1px #e6e6e6;
  }
  tr:hover td:first-child,
  tr.is-selected td:first-child {
    box-shadow: 5px 0px 0px #f49e70 inset;
  }

  .link a,
  .link a:hover {
    cursor: pointer;
    color: #b751b5;
  }

  // Ordenamiento de columnas
  .table_sort_container {
    display: inline-flex;
    flex-direction: column;
  }
  .icon_sort {
    cursor: pointer;
    line-height: 12px;
    font-size: 23px;
  }
  .icon_sort:hover,
  .icon_sort.active {
    color: white;
  }

  .table:not(:last-child) {
    margin-bottom: 0;
  }

  .image_main {
    width: 170px;
  }
</style>
